import React from "react";
import Skeleton from "react-loading-skeleton";
import validateVar from "../../../Functions/validation/validateVariable";

const DynamicText = (props) => {
    let is_loaded = (validateVar(props.is_loaded)) ? props.is_loaded : true;
    const {object, property, height, width} = props;
    let internal_height = (height) ? height :  24;
    let internal_width = (width) ? width : "100%";
    return (is_loaded) ? (validateVar(object)) ? object[property] : property : <Skeleton width={internal_width} height={internal_height} />
};
export default DynamicText;