import React from 'react';
import PrimaryButton from "../../../../module_components/Displays/Buttons/PrimaryButton";
import FileUploadModal from "../Components/FileUploadModal";
import DropdownButton from "../../../../module_components/Displays/Buttons/DropdownButton";
import validateVar from "../../../../module_components/Functions/validation/validateVariable";
import Table from "../../../../module_components/Displays/Tables/Table";

const columns = [
    {
        dataField: "value",
        text: 'Dateiname',
        sort: true,
        classes: 'linked-col bold-font'
    },
    {
        dataField: "date",
        text: "Datum",
        sort: true,
        classes: 'linked-col'
    },
    {
        dataField: "file_category_display",
        text: "Kategorie",
        sort: true,
        classes: 'linked-col'
    },
];

class FilesTable extends React.Component{
    constructor(props) {
        super(props);

        this.state = {
            dropdownDisabled: true,
            selectedIDs: [],
            fileIDs: [],
            modal_is_loading: false,
            dropdownOptions: ['delete', 'export']
        };

        this.fileUploadModal = React.createRef();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const {files} = this.props;
        const {modal_is_loading} = this.state;

        if(validateVar(files) && files !== prevProps.files && modal_is_loading){
            this.setState({modal_is_loading: false})
            this.fileUploadModal.current.toggleModal();
        }
    }

    uploadFile = (file_category, file) => {
        this.setState({modal_is_loading: true})
        let params = {
            file_category: file_category,
        };
        this.props.uploadFileForCustomer(params, file);
    };

    deleteFromTable = () => {
        let selectedIDs = this.state.selectedIDs;
        var selected_files = [];
        selectedIDs.forEach(
            id => {
                let selected_file = this.props.files.find(x => x.id === id);
                selected_files.push(selected_file);
            }
        );
        this.setState({selectedIDs: [], fileIDs: [], dropdownDisabled: true});
        this.props.delete('files', selected_files)
    };

    downloadFromTable = () => {
        let fileIDs = this.state.fileIDs;
        this.props.downloadFiles(fileIDs);
        this.setState({
            selectedIDs: [],
            fileIDs: [],
            dropdownDisabled: true
        });
    };

    rowSelected = (selected_ids) => { //3 parameter could be "rowIndex"
        const {files} = this.props;
        let {dropdownDisabled} = this.state;

        let fileIDs = [];
        for(let id of selected_ids){
            let file = files.find(file => file.id === id)
            fileIDs.push(file.key);
        }

        //Enable / Disable options dropdown Button
        if(dropdownDisabled && selected_ids.length > 0) dropdownDisabled = false;
        else if(!dropdownDisabled && selected_ids.length === 0) dropdownDisabled = true;

        this.setState({fileIDs: fileIDs, selectedIDs: selected_ids, dropdownDisabled: dropdownDisabled});
    };

    render() {
        const {files, is_loaded, dynamic_action_call, default_file_upload} = this.props;
        const {dropdownDisabled, dropdownOptions, selectedIDs, modal_is_loading} = this.state;

        return (<>
            <FileUploadModal uploadFile={this.uploadFile}
                             ref={this.fileUploadModal}
                             is_loading={modal_is_loading}
                             dynamic_action_call={dynamic_action_call}
                             default_file_upload={default_file_upload}
            />
            <Table data={files}
                columns={columns}
                is_loaded={is_loaded}
                paginator_strings={['Dateien', 'Dateien']}
                empty_table_message={'Es gibt keine Dateien'}
                selected_ids={selectedIDs}
                on_checkbox_click={this.rowSelected}
                no_title_row
                break_width='700'
            >
                <span className='responsive-button-wrapper'>
                    <DropdownButton
                        tooltipProp={"Dateien"}
                        wrapper_classes='mr-3'
                        disabled={dropdownDisabled}
                        include={dropdownOptions}
                        deleteSelected={() => this.deleteFromTable()}
                        downloadFromTable={() => this.downloadFromTable()}
                        classes={'table-header-options'}
                        disableTooltip
                    />
                </span>
                <span className='responsive-button-wrapper'>
                    <PrimaryButton id='uploadNewFile' onClick={() => this.fileUploadModal.current.toggleModal()} value='Datei hinzufügen' classes='mr-3' />
                </span>
            </Table>
        </>)
    }
}

export default FilesTable;