import React from 'react';
import AuthHeader from "../../../module_components/Layouts/Header/AuthHeader";
import {Container, Card, CardBody, Row, Col} from "reactstrap";
import connect from "react-redux/es/connect/connect";
import {
    downloadDocument,
    getContractForOffer,
    getOfferByToken,
    initializeSingleContract,
    loadAndCheckInsignSession,
    updateContract, upload_snigned_contract
} from "../../../module_components/Index/index";
import validateVar from "../../../module_components/Functions/validation/validateVariable";
import LoadingWheel from "../../../module_components/LoadingAnimations/LoadingWheel";
import {apiError} from "../../../module_components/Functions/global";

class ContractSignedSuccess extends React.Component {
    constructor(props) {
        super(props);

        const searchObject = new URLSearchParams(window.location.search);
        this.in_sign_session_id = searchObject.get('insignsessionid');
        this.offer_token = this.props.match.params.offer_token;
        this.employee_id = this.props.match.params.employee_id;
        this.consultant_id = this.props.match.params.consultant_id;
        this.error = false;
        this.state = {
            is_loaded: false,
            expired: false,
            required_sign_fields_remaining: false,
        }
    };

    componentDidMount() {
        if (validateVar(this.in_sign_session_id)) {
            this.props.loadAndCheckInsignSession(this.in_sign_session_id); //refresh session in case its expired
        }else {
            this.setState({
                is_loaded: true,
                error: true
            })
        }
    }

    checkPropsForError = (props_arr) => {
        const {is_loaded} = this.state;
        for (let index in props_arr) {
            if (props_arr.hasOwnProperty(index)) {
                if (props_arr[index] === apiError) {
                    this.error = true;
                    if(!is_loaded) this.setState({is_loaded: true});
                }
            }
        }
    };

    componentDidUpdate(prevProps) {
        const {offer, contract, insign, tariff, signed_blob, upload_file} = this.props;
        const {is_loaded} = this.state;

        let all_props = [offer, contract, insign, tariff, signed_blob, upload_file];
        this.checkPropsForError(all_props);

        if(validateVar(insign) && insign !== prevProps.insign && !this.error) {
            this.props.getOfferByToken(this.offer_token, true);
        }

        if(validateVar(offer) && offer !== prevProps.offer && !this.error) {
            this.props.getContractForOffer({
                offer_id: offer.offer_id,
                customer_id: offer.customer_id,
                consultant_id: offer.consultant_id,
            }, true);
        }

        if(validateVar(tariff) && tariff !== prevProps.tariff && !this.error) {
            if (validateVar(tariff) && tariff.contract_status !== 'submitted') {
                if (insign.requiredSignatureFieldsSigned === insign.requiredSignatureFields) { //check if required signature fields are filled
                    this.props.downloadDocument({session_id: this.in_sign_session_id, docid: tariff.contract_file_id});
                }
            }else if(!is_loaded) this.setState({is_loaded: true});
        }

        if(validateVar(signed_blob) && signed_blob !== prevProps.signed_blob && !this.error){ // upload signed contract file when signed_blob has a response
            let signed_contract_blob = signed_blob;
            signed_contract_blob.lastModifiedDate = new Date();
            signed_contract_blob.name = "contract_"+tariff.contract_id;
            this.props.upload_signed_contract({customer_id: offer.customer_id, contract_id: tariff.contract_id}, signed_contract_blob)
        }

        if(validateVar(upload_file) && upload_file !== prevProps.upload_file && !this.error) { // if signed contract got uploaded, update the contract
            let expires_at = +offer.expires_at_seconds;
            if(expires_at > 0 ) {
                this.props.updateContract({ //getTariff
                    offer_id: offer.offer_id,
                    consultant_id: offer.consultant_id,
                    customer_id: offer.customer_id,
                    employee_id: this.employee_id,
                    status: 'submitted',
                }, true)
            }else {
                this.setState({expired: true});
            }
        }
        if(validateVar(contract) && contract !== prevProps.contract && !this.error) {
            if(contract.status === 'submitted') this.setState({is_loaded: true});
        }
    }

    getPageContent = () => {
        const {is_loaded, expired} = this.state;
        let pageContent = [];
        pageContent['title'] = 'Ihr Auftrag wird abgeschlossen...';
        pageContent['subtitle'] = 'Bitte warten...';
        pageContent['text'] = <LoadingWheel />;

        if(is_loaded && !this.error){ //SUCCESS
            pageContent['title'] = 'Herzlichen Glückwunsch!';
            pageContent['subtitle'] = 'Ihre Auftragsanfrage wurde abgeschlossen!';
            pageContent['text'] = 'Ihre Signatur wurde dem Vertrag hinzugefügt. Sie können die Seite nun schließen.';
        } else if(is_loaded && this.error){ //ERROR
            pageContent['title'] = 'Es ist etwas Schiefgelaufen.';
            pageContent['subtitle'] = 'Bitte kontaktieren Sie ihren Makler!';
            pageContent['text'] = 'Aufgrund möglicher Wartungsarbeiten konnte der Auftrag nicht abgeschlossen werden. Bitte informieren Sie ihren Makler.';
        } else if(expired){ //OFFER EXPIRED
            pageContent['title'] = 'Das Angebot ist Abgelaufen.';
            pageContent['subtitle'] = 'Bitte kontaktieren Sie ihren Makler!';
            pageContent['text'] = 'Alle Angebote müssen innerhalb einer Frist abgeschlossen werden. Kontaktieren Sie bitte Ihren Makler um ein neues Angebot zu erhalten.';
        }

        return pageContent;
    };

    render() {
        const {title, subtitle, text} = this.getPageContent();

        return(<div>
            <AuthHeader title={title} lead="" />
            <Container className="mt--8 pb-5" fluid>
                <Card className='guest-success-card'>
                    <CardBody className='text-center'>
                        <Row className='mb-4'>
                            <Col>
                                <h2 className='mb-3'>{subtitle}</h2>
                                {text}
                            </Col>
                        </Row>
                    </CardBody>
                </Card>
            </Container>
        </div>)
    }
}

let mapStateToProps = function(state) {
    return {
        offer: state.offer,
        contract: state.contract_single,
        insign: state.insign,
        tariff: state.tariffs.tariff,
        signed_blob: state.signed_blob,
        upload_file: state.upload_file,
    };
};

let mapDispatchToProps = {
    getOfferByToken: getOfferByToken,
    updateContract: updateContract,
    loadAndCheckInsignSession: loadAndCheckInsignSession,
    getContractForOffer: getContractForOffer,
    downloadDocument: downloadDocument,
    upload_signed_contract: upload_snigned_contract,
    initializeSingleContract: initializeSingleContract,
};

let ContractSignedSuccessContainer = connect(mapStateToProps, mapDispatchToProps)(ContractSignedSuccess);

export default ContractSignedSuccessContainer;