import React from 'react';
import Skeleton from 'react-loading-skeleton';
import validateVar from "../Functions/validation/validateVariable";

export default function CustomerInfoItem(props){
    const {title, object, property, is_loaded} = props;

    return <div className='d-inline-block'>
        <h4>{title}</h4>
        <div>{is_loaded ? ((validateVar(object)) ? object[property] : property) : <Skeleton height={24} />}</div>
    </div>
}