/*!

=========================================================
* Argon Dashboard PRO React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
// nodejs library that concatenates classes
// reactstrap components
import {
    Card,
    CardBody,
    Container,
    Row,
    Col
} from "reactstrap";
// core components
import AuthHeader from "../../../module_components/Layouts/Header/AuthHeader.jsx";
import PrimaryButton from "../../../module_components/Displays/Buttons/PrimaryButton";
// validation
import {activateEmployee} from "../../../module_components/Index/index";
import connect from "react-redux/es/connect/connect";
import getUrlParam from "../../../module_components/Functions/getUrlParams";
import {encriptKey} from "../../../module_components/Functions/global";
import InputHandler from "../../../module_components/Inputs/InputHandler";
import Validation from "../../../module_components/Objects/Components/validation";

class Register extends React.Component {
    constructor(props) {
        super(props);
        this.key = encriptKey;

        this.state = {
            form_data: {
                first_name: "",
                last_name: "",
                phone: "",
                password: "",
            },
            error_messages: {},
            loading: false,
            activation_token: "",
        };
    }

    componentDidMount() {
        let urlParams = getUrlParam("register");
        this.setState({
            activation_token: urlParams.register,
            email: urlParams.email,
            company: urlParams.company,
        });
    }

    handle_key_press = (e) => {//Allow submit on "Enter" press
        if (e.key === 'Enter') {
            this.handle_submit_register();
        }
    };

    change_form_data = (name, value) => {
        let {form_data} = this.state;
        form_data[name] = value;
        this.setState({form_data: form_data});
    }

    handle_submit_register = () => {
        const {form_data, activation_token} = this.state;

        // validation start
        // define special cases for error validation
        let special_cases = {
            phone: {case_name: "phone"},
            password: {case_name: "new_password"},
        };
        // call abstract error validation and set values in state
        let validation_result = new Validation(form_data, special_cases);
        this.setState({
            error_messages: validation_result.error_messages
        });
        // validation end

        if (!validation_result.has_error) {//Wenn alle Eingaben stimmen, loadinAnimation anzeigen
            let form_data_ = form_data;
            form_data_.activation_token = activation_token;
            this.setState({loading: true});
            this.props.onUpdate(form_data_);
        }
    }

    render() {
        const {error_messages, form_data, company, email, loading} = this.state;
        const {first_name, last_name, phone, password} = form_data;

        return (<div className="main-content" ref="mainContent">
            <AuthHeader
                title="Herzlich Willkommen!"
                lead={<span>Ihnen wurde mit der E-Mail <b className='reset-bold'>{email}</b> Zugriff auf <b
                    className='reset-bold'>{company}</b> gewährt.</span>}
            />
            <Container className="mt--8 pb-5">
                <Row className="justify-content-center">
                    <Col lg="5" md="7">
                        <Card className="bg-secondary border-0 mb-0">
                            <CardBody className="px-lg-5 py-lg-5">
                                <div className="text-center text-muted mb-4">
                                    <small>Bitte melden Sie sich an.</small>
                                </div>
                                <InputHandler
                                    type='text'
                                    name='first_name'
                                    value={first_name}
                                    placeholder='Vorname'
                                    on_change={this.change_form_data}
                                    prefix_addon_class='single-02'
                                    on_key_down={this.handle_key_press}
                                    error_state={error_messages.first_name_error}
                                />

                                <InputHandler
                                    type='text'
                                    name='last_name'
                                    value={last_name}
                                    placeholder='Nachname'
                                    on_change={this.change_form_data}
                                    prefix_addon_class='single-02'
                                    on_key_down={this.handle_key_press}
                                    error_state={error_messages.last_name_error}
                                />

                                <InputHandler
                                    type='text'
                                    name='phone'
                                    value={phone}
                                    placeholder='Telefonnummer'
                                    on_change={this.change_form_data}
                                    prefix_addon_class='mobile-button'
                                    on_key_down={this.handle_key_press}
                                    error_state={error_messages.phone_error}
                                />

                                <InputHandler
                                    type='password'
                                    name='password'
                                    value={password}
                                    placeholder='Passwort'
                                    on_change={this.change_form_data}
                                    prefix_addon_class='lock-circle-open'
                                    on_key_down={this.handle_key_press}
                                    error_state={error_messages.password_error}
                                />

                                <div className="text-center">
                                    <PrimaryButton onClick={this.handle_submit_register}
                                       id='submitRegister'
                                       disabled={loading}
                                       loading={loading}
                                       value='Registrieren'
                                       classes='btn-modal'/>
                                </div>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </div>);
    }
}

let mapStateToProps = function (state) {
    return {
        company: state.company
    }
};

let mapDispatchToProps = {
    onUpdate: activateEmployee,
};

let RegisterContainer = connect(mapStateToProps, mapDispatchToProps)(Register);

export default RegisterContainer;

