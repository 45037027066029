/*!

=========================================================
* Argon Dashboard PRO React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
// nodejs library that concatenates classes
// reactstrap components
import {
    Card,
    CardBody,
    Container,
    Row,
    Col
} from "reactstrap";
// core components
import AuthHeader from "../../../module_components/Layouts/Header/AuthHeader.jsx";
import validateVar from "../../../module_components/Functions/validation/validateVariable";
import {resetEmployee, get_plattform_settings, redirect} from "../../../module_components/Index/index";
import connect from "react-redux/es/connect/connect";
import PrimaryButton from "../../../module_components/Displays/Buttons/PrimaryButton";
import {PasswordRecoveryPath} from "../../../globalNavigation";
import InputHandler from "../../../module_components/Inputs/InputHandler";
import Validation from "../../../module_components/Objects/Components/validation";
import {loginEmployee} from "../../../module_components/Index";

class Login extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            disabled_submit: false,
            loading: false,
            user_role: "",
            form_data: {
                email: '',
                password: ''
            },
            error_messages: {}
        };
    }


    componentDidUpdate(prevProps) {
        let employee = this.props.employee;
        if (validateVar(employee) && employee !== prevProps.employee) {
            if (employee === "ERR") {
                this.setState({
                    loading: false
                });
            }
            this.props.resetEmployee();
        }
    }

    handle_key_press = (e) => {//Allow submit on "Enter" press
        if (e.key === 'Enter') {
            this.handle_submit_login();
        }
    };

    go_to_password_recovery = () => {
        this.props.redirect(PasswordRecoveryPath)
    };

    change_form_data = (name, value) => {
        let {form_data} = this.state;
        form_data[name] = value;
        this.setState({form_data: form_data});
    }

    handle_submit_login() {
        const {form_data} = this.state;

        // validation start
        // define special cases for error validation
        let special_cases = {
            email: {case_name: "email"},
            password: {case_name: "password"},
        };
        // call abstract error validation and set values in state
        let validation_result = new Validation(form_data, special_cases);
        this.setState({
            error_messages: validation_result.error_messages
        });
        // validation end

        if (!validation_result.has_error) {
            this.setState({loading: true});
            this.props.onLogin(form_data);
        }
    }


    render() {
        const {loading, form_data, error_messages} = this.state;
        const {email, password} = form_data;

        return (<div className="main-content" ref="mainContent">
                <AuthHeader
                    title="Herzlich Willkommen!"
                    lead=""
                />
                <Container className="mt--8 pb-5">
                    <Row className="justify-content-center">
                        <Col lg="5" md="7">
                            <Card className="bg-secondary border-0 mb-0">
                                <CardBody className="px-lg-5 py-lg-5">
                                    <div className="text-center text-muted mb-4">
                                        <small>Bitte melden Sie sich an.</small>
                                    </div>
                                    <InputHandler
                                        type='text'
                                        name='email'
                                        value={email}
                                        placeholder='E-Mail'
                                        on_change={this.change_form_data}
                                        prefix_addon_class='email-83'
                                        on_key_down={this.handle_key_press}
                                        error_state={error_messages.email_error}
                                    />
                                    <InputHandler
                                        type='password'
                                        name='password'
                                        value={password}
                                        placeholder='Passwort'
                                        on_change={this.change_form_data}
                                        prefix_addon_class='lock-circle-open'
                                        on_key_down={this.handle_key_press}
                                        error_state={error_messages.password_error}
                                    />
                                    <div className="text-center">
                                        <PrimaryButton onClick={() => this.handle_submit_login()} id='submitLogin'
                                                       disabled={loading} value='Login' classes='btn-modal'
                                                       loading={loading}/>
                                    </div>
                                </CardBody>
                            </Card>
                            <Row className="mt-3">
                                <Col xs="6">
                  <span
                      className="text-light cursor-pointer"
                      onClick={() => this.go_to_password_recovery()}
                  >
                    <small>Passwort vergessen?</small>
                  </span>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Container>
            </div>
        );
    }
}

let mapStateToProps = function (state) {
    return {
        employee: state.employee
    };
};

let mapDispatchToProps = {
    onLogin: loginEmployee,
    resetEmployee: resetEmployee,
    getPlattformSettings: get_plattform_settings,
    redirect: redirect
};

let LoginContainer = connect(mapStateToProps, mapDispatchToProps)(Login);

export default LoginContainer;


