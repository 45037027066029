import React from 'react';
import DropdownButton from "../../../../module_components/Displays/Buttons/DropdownButton";
import SavedOfferInfoBox from "../Components/SavedOfferInfoBox";
import Table from "../../../../module_components/Displays/Tables/Table";
import is_true from "../../../../module_components/Functions/is_true";



class OffersTable extends React.Component{
    constructor(props) {
        super(props);

        this.state = {
            dropdownDisabled: true,
            selectedIDs: [],
            dropdownOptions: ['delete']
        };
    }

    deleteFromTable = () => {
        let selectedIDs = this.state.selectedIDs;
        var selected_offers = [];
        selectedIDs.forEach(
            id => {
                let selected_offer = this.props.offers.find(x => x.memo_id === id);
                selected_offers.push(selected_offer);
            }
        );
        this.props.delete("offers", selected_offers);
        this.rowSelected([]);
    };

    rowSelected = (selectedIDs) => {
        let {dropdownDisabled} = this.state;

        //Enable / Disable options dropdown Button
        if(dropdownDisabled && selectedIDs.length > 0) dropdownDisabled = false;
        else if(!dropdownDisabled && selectedIDs.length === 0) dropdownDisabled = true;

        this.setState({selectedIDs: selectedIDs, dropdownDisabled: dropdownDisabled});
    };

    hideSubmittedOffers = () => {
        let {offers, contracts} = this.props;

        return offers.filter((offer) => {
            let returnOffer = true;
            contracts.filter(contract => contract.id === offer.id).map(contract => {
                returnOffer = false;
            });
            if(returnOffer) return offer;
        });
    };

    render() {
        const {is_loaded, is_private} = this.props;
        const {dropdownDisabled, selectedIDs} = this.state;

        let offers = this.hideSubmittedOffers();

        let price_title = "Energiepreis";
        let socket_var = "socket_display";
        let price_var = "price_rate_full_display";
        let type = "(Netto)";
        if (is_true(is_private)) {
            price_title = "Arbeitspreis";
            socket_var = "socket_gross_display";
            price_var = "contract_price_gross_display";
            type = "(Brutto)";
        }


        const offer_columns = [
            {
                dataField: "tariff_name",
                text: 'Tarif',
                sort: true,
                classes: function callback(cell, row) { return row.expires_in === 'expired' ? 'cursor-default' : 'linked-col' }
            },
            {
                dataField: "supplier_name",
                text: "Anbieter",
                sort: true,
                classes: function callback(cell, row) { return row.expires_in === 'expired' ? 'cursor-default' : 'linked-col' }
            },
            {
                dataField: price_var,
                text: price_title + " " + type,
                sort: true,
                classes: function callback(cell, row, rowIndex, colIndex) { return row.expires_in === 'expired' ? 'cursor-default' : 'linked-col' },
                formatter: (cell, row, rowIndex) => {
                    return row.consultant_charge_unit === 'CT_PER_KWH' ?
                        <SavedOfferInfoBox price={cell} provision={row.consultant_charge_amount_display} />
                        : cell
                }
            },
            {
                dataField: socket_var,
                text: "Grundbebühr " + type,
                sort: true,
                classes: function callback(cell, row, rowIndex, colIndex) { return row.expires_in === 'expired' ? 'cursor-default' : 'linked-col' },
                formatter: (cell, row, rowIndex) => {
                    return row.consultant_charge_unit === 'EUR_PER_MONTH' ?
                        <SavedOfferInfoBox price={cell} provision={row.consultant_charge_amount_display} />
                        : cell
                }
            },
            {
                dataField: "expires_in_display",
                text: "Gültigkeit",
                sort: true,
                classes: function callback(cell, row, rowIndex, colIndex) { return row.expires_in === 'expired' ? 'cursor-default' : 'linked-col' }
            },
        ];

        return (
            <Table data={offers}
                key_field='memo_id'
                columns={offer_columns}
                linked_col_click={(row) => this.props.redirect(row.link)}
                is_loaded={is_loaded}
                paginator_strings={['Angebote', 'Angeboten']}
                empty_table_message={'Es gibt keine gespeicherten Angebote'}
                selected_ids={selectedIDs}
                on_checkbox_click={this.rowSelected}
                no_title_row>
                <span className='responsive-button-wrapper'>
                    <DropdownButton
                        tooltipProp={"Angebote"}
                        wrapper_classes='mr-3'
                        disabled={dropdownDisabled}
                        include={['delete']}
                        deleteSelected={() => this.deleteFromTable()}
                        classes={'table-header-options'}
                        disableTooltip
                    />
                </span>
            </Table>
        )
    }
}

export default OffersTable;