import React from 'react';
import InfoBox from "../../../../module_components/Notifications/InfoBox";

class SavedOfferInfoBox extends React.Component {

    state = {
        info_box: null
    };

    setInfo = (provision) => {
        let items = ['Maklerprovision: '+provision];
        this.setState({info_box: <InfoBox classes='payment-method-info' items={items} />});
    };

    resetInfo = (rowIndex) => {
        this.setState({info_box: null});
    };

    render() {
        const {info_box} = this.state;
        const {price, provision} = this.props;

        return(<>
                {price} <i onMouseEnter={() => this.setInfo(provision)} onMouseLeave={() => this.resetInfo()} className="ml-1 fas fa-info-circle cursor-pointer" /> {info_box}
            </>)
    }
}

export default SavedOfferInfoBox;