import React from 'react';
import {
    Row,
    Col,
    Card,
    CardBody,
    CardHeader,
    Form,
    Modal
} from "reactstrap";
import PrimaryButton from "../../../../module_components/Displays/Buttons/PrimaryButton";
import getValidFileName from "../../../../module_components/Functions/validation/getValidFileName";
import InputHandler from "../../../../module_components/Inputs/InputHandler";
import Validation from "../../../../module_components/Objects/Components/validation";

const selectOptions = [
    {key: 'authorization', value: 'Maklervollmacht'},
    {key: 'privacy_statement', value: 'DSGVO-Vereinbarung'},
    {key: 'service_agreement', value: 'Dienstleistungsvereinbarung'},
    {key: 'invoice', value: 'Lieferanten-Rechnung'}, //todo not implemented yet
    {key: 'other', value: 'Sonstiges'},
];

class FileUploadModal extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            showModal: false,
            submitError: "",
            file: {},
            file_type: '',
            error_messages: {
                file_error: '',
                file_type_error: '',
            }
        };
    }

    toggleModal = () => {
        this.setState({showModal: !this.state.showModal, file: {}, file_type: ''})
    };

    submit = () => {
        const {file, file_type} = this.state;

        // validation start
        let validation_data = {
            file: file,
            file_type: file_type,
        };

        // define special cases for error validation
        let special_cases = {
            file_type: {case_name: "select"},
            file: {case_name: "file"},
        }

        let validation_result = new Validation(validation_data, special_cases);
        this.setState({
            error_messages: validation_result.error_messages
        });

        if (!validation_result.has_error){
            this.props.uploadFile(file_type, file);
        }
    };

    handleFileChange = (file) => {
        let myNewFile = getValidFileName(file);
        this.setState({file: myNewFile});
    };

    handleSelectBoxChange = (id, value) => {
        this.setState({
            file_type: value,
        })
    };

    render(){
        const {file, file_type} = this.state;
        const {is_loading, dynamic_action_call, default_file_upload} = this.props;
        const {file_error, file_type_error} = this.state.error_messages;

        return(
            <Modal
                className="modal-dialog-centered"
                size="md"
                isOpen={this.state.showModal}
                toggle={() => this.toggleModal()}
            >
                <div className="modal-body p-0">
                    <Card className="bg-secondary border-0 mb-0">
                        <CardHeader className="bg-transparent modal-title">
                            <Row>
                                <Col lg={6}>
                                    <h2 className={'mb-0'}>Datei hinzufügen</h2>
                                </Col>
                                <Col lg={6}>
                                    <button onClick={() => this.toggleModal()} className={'close'} ><i className={'ni ni-fat-remove close-button'} /></button>
                                </Col>
                            </Row>
                        </CardHeader>
                        <CardBody className="px-lg-4 py-lg-4">
                            <Form role="form" className='mb-0'>
                                <Row className='mb-4'>
                                    <Col>
                                        <InputHandler type='select'
                                                      id='fileType'
                                                      content_title='Typ'
                                                      name='file_type'
                                                      select_options={selectOptions}
                                                      value={file_type}
                                                      disabled={is_loading}
                                                      on_change={(name, value) => this.handleSelectBoxChange(name, value)}
                                                      error_state={file_type_error}
                                                      no_margin/>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <InputHandler content_title='Datei'
                                                      type="file_upload_new"
                                                      name='file'
                                                      value={file.name}
                                                      file_type="pdf" // images / pdf / xlsx -> default
                                                      return_file={(file) => this.handleFileChange(file)}
                                                      allow_reupload={true}
                                                      error_state={file_error}
                                                      dynamic_action_call={dynamic_action_call}
                                                      default_file_upload={default_file_upload}
                                                      no_margin
                                        />
                                    </Col>
                                </Row>

                                <div className={'modal-text-section mt-4 pt-3'}>
                                    Bitte achten Sie auf die folgende Vorgaben, um eine Datei hochzuladen.
                                    <ul className='checklist'>
                                        <li className='ni ni-check-bold'><span>PDF-Dateiformat</span></li>
                                        <li className='ni ni-check-bold'><span>Maximale Dateigröße 5 MB</span></li>
                                    </ul>
                                </div>
                                <PrimaryButton loading={is_loading} disabled={is_loading} id='createPFCSubmit' onClick={this.submit} value='Datei hinzufügen' classes='btn-modal full-width-button' />
                            </Form>
                        </CardBody>
                    </Card>
                </div>
            </Modal>
        );
    }
}

export default FileUploadModal;