import React from 'react';
import validateVar from "../Functions/validation/validateVariable";

class InfoBox extends React.Component {

    render() {
        const {items, header, classes} = this.props;

        return(<div className={'info-box ' + classes}>
            {validateVar(header) && <div className='info-box-header'>
                {header}
            </div>}
            <div className='info-box-body'>
                {items.map((item) => {
                    if(item === 'separator')return <div className='info-box-separator'/>
                    return <div className='info-box-body-item'>{item}</div>
                })}
            </div>
        </div>)
    }
}

export default InfoBox;