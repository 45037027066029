import React from "react";
import PropTypes from "prop-types"
import validateVar from "../../Functions/validation/validateVariable";
import Skeleton from "react-loading-skeleton";

//prop.color: green/orange/red

const BadgeQuadratic = (props) => {
    let is_loaded = (validateVar(props.is_loaded)) ? props.is_loaded : true;
    return (is_loaded ? (<span className={'new-badge-pill badge-'+ props.color +' '+props.classes}>{props.text}</span>) : <Skeleton width={48} height={16} />)
};

BadgeQuadratic.propTypes = {
    color: PropTypes.oneOf(['green', 'orange', 'red', 'gray']),
    text: PropTypes.string,
    classes: PropTypes.string,
};

export default BadgeQuadratic;