import React from 'react';
import DropdownPrimaryButton from "../../../../module_components/Displays/Buttons/DropdownPrimaryButton";
import DropdownButton from "../../../../module_components/Displays/Buttons/DropdownButton";
import {ConsumptionPoint} from "../../../../globalNavigation";
import TooltipComponent from "../../../../module_components/Displays/Basic/Tooltip";
import Table from "../../../../module_components/Displays/Tables/Table";

const default_table_columns = [
    {
        dataField: "consumption_point_street",
        text: 'Straße',
        sort: true,
        classes: 'linked-col bold-font'
    },
    {
        dataField: "postal_code",
        text: "PLZ",
        sort: true,
        classes: 'linked-col'
    },
    {
        dataField: "city",
        text: "Stadt",
        sort: true,
        classes: 'linked-col'
    },
    {
        dataField: "consumption_display",
        text: "Verbrauch",
        sort: true,
        classes: 'linked-col'
    },
    {
        dataField: "consumption_type",
        text: "Typ",
        sort: true,
        classes: 'linked-col'
    },
    {
        dataField: "medium_type_display",
        text: "Energie",
        sort: true,
        classes: 'linked-col'
    },
    {
        dataField: "market_identifier",
        text: "Malo-ID",
        sort: true,
        classes: 'linked-col'
    },
    {
        dataField: "reference",
        text: "Referenz",
        sort: false,
        classes: '',
        formatter: (cell, object) => <TooltipComponent tooltip_data={{id: 'tooltip_'+object.id, text: cell}} />
    },
];

class ConsumptionPointsTable extends React.Component{
    state = {
        dropdownDisabled: true,
        selectedIDs: [],
        redirectToConsumptionPoint: false,
        table_columns: default_table_columns,
        dropdownOptions: ['delete']
    };

    rowSelected = (selectedIDs) => { //3 parameter could be "rowIndex"
        let {dropdownDisabled} = this.state;

        //Enable / Disable options dropdown Button
        if(dropdownDisabled && selectedIDs.length > 0) dropdownDisabled = false;
        else if(!dropdownDisabled && selectedIDs.length === 0) dropdownDisabled = true;

        this.setState({selectedIDs: selectedIDs, dropdownDisabled: dropdownDisabled});
    };

    deleteFromTable = () => {
        let selectedIDs = this.state.selectedIDs;
        var selected_cspoints = [];
        selectedIDs.forEach(
            id => {
                let selected_cspoint = this.props.consumptionPoints.find(x => x.id === id);
                selected_cspoints.push(selected_cspoint);
            }
        );
        this.props.delete("consumption_points", selected_cspoints);
        this.rowSelected([]);
    };

    render() {
        const {consumptionPoints, is_loaded} = this.props;
        const {table_columns, selectedIDs} = this.state;
         return (<Table table_name={"enermakler_consumption_point_list_by_customer"}
                     data={consumptionPoints}
                     columns={table_columns}
                     linked_col_click={(row) => this.props.redirect(ConsumptionPoint+'/'+ row.medium_type_display.toLowerCase() + "/" + this.props.customer_id + "/" + row.id)}
                     is_loaded={is_loaded}
                     paginator_strings={['Lieferstellen', 'Lieferstellen']}
                     key_field={"consumption_point_id"}
                     empty_table_message={'Es gibt noch keine Lieferstellen'}
                     selected_ids={selectedIDs}
                     on_checkbox_click={this.rowSelected}
                     update_columns={(table_columns) => this.setState({table_columns: table_columns})}
                     no_title_row>
                        <span className='responsive-button-wrapper'>
                            <DropdownButton
                                tooltipProp={"Lieferstellen"}
                                wrapper_classes='mr-3'
                                disabled={this.state.dropdownDisabled}
                                include={['delete']}
                                deleteSelected={() => this.deleteFromTable()}
                                classes={'table-header-options'}
                            />
                        </span>
                        <span className='responsive-button-wrapper'>
                            <DropdownPrimaryButton redirect={(url) => this.props.redirect(url)} customer_id={this.props.customer_id} classes='mr-3'  />
                        </span>
         </Table>)
    }
}

export default ConsumptionPointsTable;