import React from 'react';
import SimpleHeader from "../module_components/Displays/Headers/SimpleHeader";

class PageNotFound extends React.Component {

    render() {
        return                 <div
            className="main-content"
            ref="mainContent"
            onClick={this.closeSidenav}
        >
            <SimpleHeader name="Seite nicht gefunden" headerText="Überprüfen Sie bitte die eingegebene URL noch einmal." />
        </div>
    }
}

export default PageNotFound;