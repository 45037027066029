/*!

=========================================================
* Argon Dashboard PRO React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import 'core-js/features/string/repeat';
import React from "react";
import ReactDOM from "react-dom";
// react library for routing
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";

// plugins styles from node_modules
import "react-notification-alert/dist/animate.css";
import "react-perfect-scrollbar/dist/css/styles.css";
import "cropperjs/dist/cropper.css";
// plugins styles downloaded
import "assets/vendor/fullcalendar/dist/fullcalendar.min.css";
import "assets/vendor/sweetalert2/dist/sweetalert2.min.css";
import "assets/vendor/select2/dist/css/select2.min.css";
import "assets/vendor/quill/dist/quill.core.css";
import "assets/vendor/nucleo/css/nucleo.css";
import "assets/vendor/@fortawesome/fontawesome-free/css/all.min.css";
import "react-datepicker/dist/react-datepicker.css";
// core styles
import "assets/scss/argon-dashboard-pro-react.scss?v1.0.0";
// layouts
import AdminLayout from "./module_components/Layouts/Admin.jsx";
import AuthLayout from "./module_components/Layouts/Auth.jsx";
// functions
import getUserRole from "./module_components/Functions/getUserRole";
import checkUserLogin from "./module_components/Functions/checkUserLogin";
import checkGuestUrl from "./module_components/Functions/checkGuestUrl";
// Redux
import { createStore, applyMiddleware, compose } from "redux";
import thunk from "redux-thunk";
import { Provider } from "react-redux";
import rootReducer from "./module_components/Reducer";
// pages
import {
    LoginPath,
    PasswordRecoveryPath,
    RegisterPath,
    SetNewPasswordPath,
    SuccessPage
} from "./globalNavigation";

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const store = createStore(
    rootReducer,
    composeEnhancers(applyMiddleware(thunk))
);


const PrivateRoute = ({ component: Component, ...rest }) => {
    var authentication = getUserRole();
    return (
        <Route
            {...rest}
            render={props =>
                (authentication === "admin" || authentication === "manager" || authentication === "user") ?
                    <AdminLayout {...props}  />
                    :  <AuthLayout denied={true} {...props}  />
            }
        />
    );
};

if (!checkGuestUrl()) {
    checkUserLogin();
}

ReactDOM.render(
    <Provider store={store}>
      <BrowserRouter>
        <Switch>
          <Route path={LoginPath} render={props => <AuthLayout {...props} />} />
          <Route path={PasswordRecoveryPath} render={props => <AuthLayout {...props} />} />
          <Route path={SetNewPasswordPath} render={props => <AuthLayout {...props} />} />
          <Route path={RegisterPath} render={props => <AuthLayout {...props} />}/>
          <Route path={SuccessPage} render={props => <AuthLayout {...props} contract_guest_page />}/>
          <PrivateRoute path={"/:path/:path?"} />
          <Redirect from="/" to={"/dashboard"} />
        </Switch>
      </BrowserRouter>
    </Provider>,
  document.getElementById("root")
);
