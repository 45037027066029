import React from "react";
import Button from "reactstrap/lib/Button";
import ReactCSSTransitionGroup from 'react-addons-css-transition-group';
import {Redirect} from "react-router";
import {ConsumptionpointPath, Electricity, Gas} from "../../../globalNavigation";

class DropdownButtonPrimary extends React.Component {
    constructor (props) {
        super(props);
        this.state = {
            active: false,
            dropdown: '',
            addGasCsp: false,
        };
    }

    componentDidMount() {
        document.addEventListener('mousedown', this.handleClickOutside);
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClickOutside);
    }

    handleClickOutside = (event) => {
        if (this.wrapperRef && this.state.active && !this.wrapperRef.contains(event.target)) { //close the button on click outside
            this.buttonClicked();
            this.setState({dropdown: ''});
        }
    };

    setWrapperRef = (node) => {
        this.wrapperRef = node;
    };

    buttonClicked = () => {
        var dropdownOptions = <div key={0} className={'dropdown-options primary two-options'}>
            <div key={0}
                 className='first-primary-option'
                 onClick={() => this.props.redirect(ConsumptionpointPath+Electricity+'/'+this.props.customer_id+'/0')}>Strom</div>
            <div key={1}
                 onClick={() => this.props.redirect(ConsumptionpointPath+Gas+'/'+this.props.customer_id+'/0')}>Gas</div>
        </div>;

        this.setState({active: !this.state.active});

        if(this.state.active === false){
            this.setState({dropdown: dropdownOptions});
        }else{
            this.setState({dropdown: ''})
        }
    };

    toggle = () => {
        if(this.props.disabled)
        this.setState({tooltipOpen: !this.state.tooltipOpen});
    };

    render(){
        var activeClass = '';

        if(this.state.active){
            activeClass = 'active'
        }else{
            activeClass = '';
        }

        return(
            <>
                <div className='position-absolute'>{this.state.alert}</div>
                <div ref={this.setWrapperRef} id={'optionenButton'} className={'dropdown-button-wrapper'}>
                    <Button onClick={this.buttonClicked} className={'btn eless-btn primary-btn white-color dropdown-button ' + activeClass + ' ' + this.props.classes}>
                        Lieferstelle hinzufügen
                    </Button>
                    <ReactCSSTransitionGroup
                        transitionName="fade"
                        transitionEnterTimeout={500}
                        transitionLeaveTimeout={500}>
                        {this.state.dropdown}
                    </ReactCSSTransitionGroup>
                </div>
            </>
        )
    }
}

export default DropdownButtonPrimary;